import React, { Component } from "react"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  TextField,
} from "@mui/material"
import { cloneDeep } from "lodash"
import { getUserInfo } from "../../utils/user"
import { getTaxRate } from "../../utils/q"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
const Freight = 0.15
const FreightInsurance = 0.015

class ProjectPreview extends Component {
  constructor(props) {
    super(props)

    this.state = {
      project: null,
      loading: "false",
      taxRate: { rate: 0, handlingTaxable: true, freightTaxable: true },
      taxZip: this.props.taxZip ? this.props.taxZip : "",
      error: null,
      includeTax: false,
      includeFreightInsurance: false,
      disableQuickQuote: true,
      userInfo: null,
      fsRep: {
        name: "F+S Marketing",
        title: "",
        phone: "800.451.0410",
        email: "marketing@forms-surfaces.com",
        location: "Pittsburgh / Carpenteria / Phoenix",
      },
      openSummary: false,
    }
  }

  componentDidMount() {
    this.handleGetUserInfo()
  }

  handleFocus = event => event.target.select()

  handleGetUserInfo = () => {
    this.setState({
      loading: true,
    })
    getUserInfo()
      .then(info => {
        let contactInfo = {}
        let fsRep = { ...this.state.fsRep }
        let taxZip = this.props.taxZip
        contactInfo.name = info?.name?.first + " " + info?.name?.last
        contactInfo.company = info?.company
        contactInfo.email = info?.email
        contactInfo.phone = info?.phone
        contactInfo.zip = info?.postal_code
        fsRep.name = contactInfo.name
        fsRep.phone = contactInfo.phone
        fsRep.email = contactInfo.email
        fsRep.location = ""
        getTaxRate(taxZip)
          .then(
            function (data) {
              this.setState({
                userInfo: info,
                fsRep: fsRep,
                disableQuickQuote: false,
                taxRate: data,
                taxZip: taxZip,
              })
              this.props.setRepTaxRate({
                userInfo: info,
                fsRep: fsRep,
                taxRate: data,
                includeTax: this.state.includeTax,
              })
            }.bind(this)
          )
          .catch(function (err) {
            this.props?.setError(err)
          })

        this.setState({
          userInfo: info,
          contactInfo: contactInfo,
          loading: false,
        })
      })
      .catch(err => {
        this.setState({
          errMsg: err.toString(),
          loading: false,
        })
      })
  }

  handleChangeQuantity = (id, quantity) => {
    this.props.handleDesignQuantityUpdate(id, quantity)
  }

  handleFreightInsuranceChange = e => {
    this.setState({
      includeFreightInsurance: e.target.value === "true" ? true : false,
    })
    this.props.setRepTaxRate({
      userInfo: this.state.userInfo,
      fsRep: this.state.fsRep,
      taxRate: this.state.taxRate,
      includeTax: this.state.includeTax,
      includeFreightInsurance: e.target.value === "true" ? true : false
    })
  }

  handleTaxChange = e => {
    this.setState({
      includeTax: e.target.value === "true" ? true: false,
    })
    this.props.setRepTaxRate({
      userInfo: this.state.userInfo,
      fsRep: this.state.fsRep,
      taxRate: this.state.taxRate,
      includeTax: e.target.value === "true" ? true: false,
      includeFreightInsurance: this.state.includeFreightInsurance
    })
  }

  handleZipUpdate = e => {
    this.setState({
      taxZip: e.target.value,
    })
  }

  submitZipUpdate = e => {
    this.props.handleZipUpdate(this.state.taxZip.trim())
    getTaxRate(this.state.taxZip)
      .then(
        function (data) {
          this.setState({
            taxRate: data,
            taxZip: this.state.taxZip.trim(),
          })
        }.bind(this)
      )
      .catch(function (err) {
        this.props.setError(err)
      })
  }

  render() {
    let designs = null
    let grandSubtotal = 0
    let freightTotal = 0
    let freightInsurance = 0
    let freight = 0

    if (this.props?.project?.designs) {
      let myDesigns = cloneDeep(this.props.project.designs)
      designs = myDesigns.map((design, i) => {
        grandSubtotal += design.pricing.subTotal
        freightTotal += design.pricing.freightSubTotal
        return (
          <Accordion
            expanded={true}
            key={i}
            style={{
              marginBottom: "1rem",
            }}
            className="pricing-preview-summary"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  {design.name}
                </div>
                <div
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: "1rem",
                  }}
                >
                  {design.pricing.subTotalPrint}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {design.pricing.lineItems.map((lineItem, j) => {
                    return (
                      <div className="project-preview-line-item" key={j}>
                        <div
                          style={{
                            backgroundColor: "#c0c0c0",
                            padding: ".5rem",
                          }}
                        >
                          {lineItem.model}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: ".5rem",
                          }}
                        >
                          <div
                            style={{
                              width: "8rem",
                            }}
                          >
                            Quantity
                          </div>
                          {j === 0 && (
                            <div
                              style={{
                                width: "calc(100% - 8rem)",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <input
                                type="number"
                                min="1"
                                max="1000"
                                onChange={e => {
                                  this.handleChangeQuantity(
                                    design._id,
                                    e.target.value
                                  )
                                }}
                                onFocus={this.handleFocus}
                                value={lineItem.quantity}
                                style={{ textAlign: "right" }}
                              />
                            </div>
                          )}
                          {j > 0 && (
                            <div
                              style={{
                                width: "calc(100% - 8rem)",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {lineItem.quantity}
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            padding: ".5rem",
                          }}
                        >
                          {lineItem.description.split("\n").map((line, z) => {
                            return (
                              <span key={z}>
                                {line}
                                <br />
                              </span>
                            )
                          })}
                        </div>

                        <div
                          style={{
                            padding: ".5rem",
                            textAlign: "right",
                          }}
                        >
                          <div>{lineItem.pricePrint}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        )
      })
    }
    freight = Math.round(freightTotal * Freight)
    freightInsurance = Math.round(freightTotal * FreightInsurance)

    if (freight < 500) {
      freight = 500
    }
    let location = []
    if (this.props.project?.city) {
      location.push(this.props.project.city)
    }
    if (this.props.project?.state) {
      location.push(this.props.project.state)
    }
    if (this.props.taxZip) {
      location.push(this.props.taxZip)
    }
    if (this.props.project?.country) {
      location.push(this.props.project.country)
    }
    let taxRate = 0
    let tax = 0
    if (this.state.includeTax) {
      taxRate = this.state.taxRate.rate
      tax = grandSubtotal * taxRate
      if (this.state.includeFreightInsurance) {
        tax = (grandSubtotal + freightInsurance) * taxRate
      }
      if (this.state.taxRate.freightTaxable) {
        tax += freight * taxRate
      }
    }
    return (
      <section
        style={{
          display: "flex",
          width: "100%",
          flexGrow: 1,
          flexDirection: "column",
          justifyContent: "space-between",
          overflow: "hidden",
        }}
        className="pricing-preview"
      >
        <div
          style={{
            width: "100%",
            fontSize: ".9rem",
            overflow: "hidden auto",
          }}
        >
          <div>
            {!this.props.showPricingSummary && (
              <Button
                className="show-pricing-summary"
                onClick={() => {
                  this.props.handleShowPricingSummary(
                    !this.props.showPricingSummary
                  )
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    Total
                  </div>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "right",
                      paddingRight: "1rem",
                    }}
                  >
                    {this.state.includeFreightInsurance && (
                      <>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(
                          grandSubtotal + freightInsurance + freight + tax
                        )}
                      </>
                    )}
                    {!this.state.includeFreightInsurance && (
                      <>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(grandSubtotal + freight + tax)}
                      </>
                    )}
                  </div>
                </div>
                <KeyboardArrowRightIcon />
              </Button>
            )}
            {this.props.showPricingSummary && (
              <div className="pricing-summary">
                <div className="invoice-total">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                      }}
                    >
                      Subtotal
                    </div>
                    <div
                      style={{
                        width: "50%",
                        textAlign: "right",
                      }}
                    >
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(grandSubtotal)}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                      }}
                    >
                      Shipping/Handling
                    </div>
                    <div
                      style={{
                        width: "50%",
                        textAlign: "right",
                      }}
                    >
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(freight)}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      opacity: this.state.includeFreightInsurance ? 100 : 0,
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                      }}
                    >
                      Freight Insurance
                    </div>
                    <div
                      style={{
                        width: "50%",
                        textAlign: "right",
                      }}
                    >
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(freightInsurance)}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      opacity: this.state.includeTax ? 100 : 0,
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                      }}
                    >
                      Tax Rate {(taxRate * 100).toFixed(2)}%
                    </div>
                    <div
                      style={{
                        width: "50%",
                        textAlign: "right",
                      }}
                    >
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(tax)}
                    </div>
                  </div>
                  <FormControl>
                    <RadioGroup
                      name="controlled-radio-buttons-group"
                      value={this.state.includeTax}
                      onChange={this.handleTaxChange}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Exclude Tax"
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Include Tax"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <RadioGroup
                      name="controlled-radio-buttons-freight"
                      value={this.state.includeFreightInsurance}
                      onChange={this.handleFreightInsuranceChange}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Exclude Freight Insurance"
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Include Freight Insurance"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormLabel>Zip Code</FormLabel>
                  <FormControl
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                    }}
                  >
                    <TextField
                      variant="standard"
                      placeholder="Zip Code"
                      readOnly={this.props.project?.locked}
                      onChange={this.handleZipUpdate}
                      style={{
                        width: "60%",
                        justifyContent: "flex-end",
                      }}
                      value={this.state.taxZip}
                    />
                    {!this.props.project?.locked && (
                      <Button
                        onClick={this.submitZipUpdate}
                        variant="contained"
                        style={{
                          width: "40%",
                          height: "2rem",
                        }}
                      >
                        Save
                      </Button>
                    )}
                  </FormControl>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                      }}
                    >
                      <strong>Total</strong>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        textAlign: "right",
                      }}
                    >
                      <strong>
                        {this.state.includeFreightInsurance && (
                          <>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(
                              grandSubtotal + freightInsurance + freight + tax
                            )}
                          </>
                        )}
                        {!this.state.includeFreightInsurance && (
                          <>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(grandSubtotal + freight + tax)}
                          </>
                        )}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!this.props.showPricingSummary && designs}
          </div>
        </div>
        <div></div>
      </section>
    )
  }
}

export default ProjectPreview
