import React, { Component } from "react"
import {
  Page,
  Text,
  View,
  Canvas,
  Document,
  Image,
  Font,
} from "@react-pdf/renderer"
import styles from "../ProductPDF/product-pdf-styles"
import pdfImages from "../ProductPDF/product-pdf-images"
import cloneDeep from "lodash.clonedeep"
const isBrowser = typeof window !== `undefined`

const Freight = 0.15
const FreightInsurance = 0.015

const formatPhoneNumber = phoneNumberString => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "")
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = match[1] ? "+1 " : ""
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
  }
  return null
}

class ProjectPDF extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  drawLine = (painter, w, h) => {
    painter.strokeColor("rgba(0,0,0,0.8)")
    painter.moveTo(0, 0).lineTo(556, 0).stroke()
  }

  drawSquare = (painter, w, h) => {
    painter.strokeColor("rgba(0,0,0,0.8)")
    painter.lineWidth(0.5)
    painter.moveTo(0, 0).lineTo(302, 0).stroke()
    painter.moveTo(302, 0).lineTo(302, 202).stroke()
    painter.moveTo(302, 202).lineTo(0, 202).stroke()
    painter.moveTo(0, 202).lineTo(0, 0).stroke()
  }

  render() {
    let hardwareKits = []
    Font.register({
      family: "Helvetica Neue",
      src: "/fonts/HelveticaNeueLTStd-LtCn.woff",
    })
    Font.register({
      family: "Helvetica Neue Bold",
      src: "/fonts/HelveticaNeueLTStd-MdCn.woff",
    })
    Font.register({
      family: "Helvetica Neue Heavy",
      src: "/fonts/HelveticaNeue-HeavyExt.ttf",
    })
    Font.register({
      family: "Open Sans",
      fonts: [
        {
          src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
        },
        {
          src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
          fontWeight: 600,
        },
      ],
    })
    let pages = []
    let header = (
      <View fixed style={styles.headerStyle}>
        <Image
          src={pdfImages.configuratorLogo2.src}
          style={pdfImages.configuratorLogo2.style}
        />
        <Canvas paint={this.drawLine} style={styles.headerLineStyle} />
        <Text fixed style={styles.projectTitle}>
          {this.props.project.name}
        </Text>
      </View>
    )
    let date = new Date()
    let revDate =
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2) +
      "-" +
      date.getFullYear().toString().substr(-2)
    let printDate =
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + date.getDate()).slice(-2) +
      "/" +
      date.getFullYear().toString()
    date.setDate(date.getDate() + 30)
    let validDate =
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + date.getDate()).slice(-2) +
      "/" +
      date.getFullYear().toString()
    let smartCode = null
    let grandSubtotal = 0
    let freightSubtotal = 0
    let freight = 0
    let freightInsurance = 0
    let footer = (
      <View fixed style={styles.footerStyle}>
        <Text fixed style={styles.footerTextStyle}>
          T 800.451.0410 | www.forms-surfaces.com
        </Text>
        <Canvas fixed paint={this.drawLine} style={styles.footerLineStyle} />
        <Text fixed style={styles.copyRight}>
          &copy; {date.getFullYear()} Forms+Surfaces&reg; | All dimensions are
          nominal. Specifications and pricing subject to change without notice.
          or the most current version of this document, please refer to our
          website at www.forms-surfaces.com.
        </Text>
        <Text
          style={styles.pageRevisions}
          fixed
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages} | Rev. ${revDate}`
          }
        />
        <Text fixed style={styles.pageInfo}>
          Project ID: {this.props.project._id}
        </Text>
      </View>
    )

    let projectText = []
    let location = []
    if (this.props.project.city) {
      location.push(this.props.project.city)
    }
    if (this.props.project.state) {
      location.push(this.props.project.state)
    }
    if (this.props.project.zip) {
      location.push(this.props.project.zip)
    }
    if (this.props.project.country) {
      location.push(this.props.project.country)
    }
    projectText.push(
      <Text key={"project-text-1"} style={styles.bodyTextStyle}>
        <Text style={{ fontFamily: "Helvetica-Bold" }}>PROJECT NAME: </Text>
        {this.props.project.name}
      </Text>
    )
    projectText.push(
      <Text key={"project-text-2"} style={styles.bodyTextStyle}>
        <Text style={{ fontFamily: "Helvetica-Bold" }}>DATE: </Text>
        {printDate}
      </Text>
    )
    projectText.push(
      <Text key={"project-text-3"} style={styles.bodyTextStyle}>
        <Text style={{ fontFamily: "Helvetica-Bold" }}>Location: </Text>
        {location.join(", ")}
      </Text>
    )

    let designGrid = this.props.project.designs.map((design, i) => {
      grandSubtotal += design.pricing.subTotal
      freightSubtotal += design.pricing.freightSubTotal

      let imageSrc = process.env.GATSBY_IMAGE_URL + design.imagePath
      let image = (
        <Image
          src={{
            uri: imageSrc,
            method: "GET",
            headers: { "Cache-Control": "no-cache" },
            body: "",
          }}
          style={{
            objectFit: "cover",
            width: 183,
            height: 121,
          }}
        />
      )
      let lineItems = design.pricing?.lineItems.map((lineItem, j) => {
        if (lineItem.hardwareKit) {
          hardwareKits.push({
            title: design.name + " " + lineItem.description,
            subTitle: lineItem.shortDescription,
            contents: lineItem.contents,
          })
        }
        return (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10",
              borderBottom: 1,
              borderColor: "#666",
            }}
          >
            <View
              style={{
                width: "60%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Helvetica",
                  fontSize: 9,
                }}
              >
                {lineItem?.model}
              </Text>
              <Text
                style={{
                  fontFamily: "Helvetica",
                  fontSize: 9,
                }}
              >
                {lineItem?.description}
              </Text>
            </View>
            <View
              style={{
                width: "18%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Helvetica",
                  fontSize: 9,
                  textAlign: "center",
                }}
              >
                Quantity
              </Text>
              <Text
                style={{
                  fontFamily: "Helvetica",
                  fontSize: 9,
                  textAlign: "center",
                }}
              >
                {lineItem?.quantity}
              </Text>
            </View>
            <View
              style={{
                width: "22%",
              }}
            >
              <Text style={styles.projectDesignText}> </Text>
              <Text
                style={{
                  fontFamily: "Helvetica",
                  fontSize: 9,
                  textAlign: "right",
                }}
              >
                {lineItem?.pricePrint}
              </Text>
            </View>
          </View>
        )
      })
      lineItems.push(
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "10",
          }}
        >
          <View
            style={{
              width: "50%",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontFamily: "Helvetica-Bold",
                fontSize: 11,
              }}
            >
              Subtotal
            </Text>
          </View>
          <View
            style={{
              width: "50%",
            }}
          >
            <Text
              style={{
                fontSize: 11,
                fontWeight: "bold",
                fontFamily: "Helvetica-Bold",
                textAlign: "right",
              }}
            >
              {design.pricing?.subTotalPrint}
            </Text>
          </View>
        </View>
      )
      return (
        <View
          wrap={false}
          style={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
          }}
          key={"design-grid" + i}
        >
          <View
            style={{
              backgroundColor: "#c0c0c0",
              width: "100%",
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Text
              style={{
                color: "#333333",
                textTransform: "uppercase",
                fontSize: 12,
                fontWeight: "bold",
                fontFamily: "Helvetica-Bold",
              }}
            >
              {design.name}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <View
              style={{
                width: "30%",
              }}
            >
              {image}
            </View>
            <View
              style={{
                width: "70%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {lineItems}
            </View>
          </View>
        </View>
      )
    })

    freight = Math.round(freightSubtotal * Freight)
    freightInsurance = Math.round(freightSubtotal * FreightInsurance)
    if (freight < 500) {
      freight = 500
    }
    let totalLine = {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      padding: 5,
      borderBottom: 1,
    }
    let taxLine = null
    let tax = 0
    let taxTitle = "Total W/O Tax"
    if (this.props.taxRate) {
      let rate = this.props.taxRate.rate
      tax = grandSubtotal * rate
      if (this.props.includeFreightInsurance) {
        tax = (grandSubtotal + freightInsurance) * rate
      }
      if (this.props.taxRate.freightTaxable) {
        tax += freight * rate
      }
      taxTitle = "Total W/Tax"
      taxLine = (
        <View style={totalLine}>
          <View
            style={{
              width: "50%",
            }}
          >
            <Text
              style={{
                fontSize: 10,
              }}
            >
              Total Tax: {(rate * 100).toFixed(2)}%
            </Text>
          </View>
          <View
            style={{
              width: "50%",
            }}
          >
            <Text
              style={{
                fontSize: 10,
                textAlign: "right",
              }}
            >
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(tax) + " USD"}
            </Text>
          </View>
        </View>
      )
    }
    if (this.props.includeFreightInsurance) {
      designGrid.push(
        <View
          wrap={false}
          style={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
          }}
          key={"design-grid-freight-insurance"}
        >
          <View
            style={{
              backgroundColor: "#c0c0c0",
              width: "100%",
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Text
              style={{
                color: "#333333",
                textTransform: "uppercase",
                fontSize: 12,
                fontWeight: "bold",
                fontFamily: "Helvetica-Bold",
              }}
            >
              Freight Insurance
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <View
              style={{
                width: "30%",
              }}
            ></View>
            <View
              style={{
                width: "70%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10",
                  borderBottom: 1,
                  borderColor: "#666",
                }}
              >
                <View
                  style={{
                    width: "60%",
                  }}
                >
                  <View
                    style={{
                      fontFamily: "Helvetica",
                      fontSize: 9,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Helvetica",
                        fontSize: 9,
                        paddingBottom: 10,
                      }}
                    >
                      ADD FOR FOB DESTINATION
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Helvetica",
                        fontSize: 9,
                        paddingBottom: 10,
                      }}
                    >
                      Receiving parties are responsible to document via digital
                      images, notate on delivery receipt prior to signing, and
                      immediately notify an F+S representative of any visible
                      damage to the exterior of crating or packaging. Actual
                      inspection of goods must be completed and any damage
                      claims filed within 48 hours of delivery.
                    </Text>{" "}
                    <Text
                      style={{
                        fontFamily: "Helvetica",
                        fontSize: 9,
                        paddingBottom: 10,
                      }}
                    >
                      Please Note: This is an Optional Added Value Service and
                      can be Declined
                    </Text>{" "}
                    <Text
                      style={{
                        fontFamily: "Helvetica",
                        fontSize: 9,
                        paddingBottom: 10,
                      }}
                    >
                      __________Decline
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    width: "18%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Helvetica",
                      fontSize: 9,
                      textAlign: "center",
                    }}
                  ></Text>
                  <Text
                    style={{
                      fontFamily: "Helvetica",
                      fontSize: 9,
                      textAlign: "center",
                    }}
                  ></Text>
                </View>
                <View
                  style={{
                    width: "22%",
                  }}
                >
                  <Text style={styles.projectDesignText}></Text>
                  <Text
                    style={{
                      fontFamily: "Helvetica",
                      fontSize: 9,
                      textAlign: "right",
                    }}
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(freightInsurance) + " USD"}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      )
    }
    if(this.props.includeFreightInsurance){
      grandSubtotal += freightInsurance
    }
    designGrid.push(
      <View
        style={{
          display: "flex",
          width: "100%",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          marginTop: 10,
        }}
        wrap={false}
      >
        <View
          style={{
            width: "50%",
          }}
        >
          <View
            style={{
              width: "100%",
              backgroundColor: "#333",
              color: "#fff",
              padding: 5,
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                textTransform: "uppercase",
                fontFamily: "Helvetica-Bold",
                fontSize: 11,
              }}
            >
              {taxTitle}
            </Text>
          </View>
          <View style={totalLine}>
            <View
              style={{
                width: "50%",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                }}
              >
                Subtotal
              </Text>
            </View>
            <View
              style={{
                width: "50%",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  textAlign: "right",
                }}
              >
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(grandSubtotal) + " USD"}
              </Text>
            </View>
          </View>
          <View style={totalLine}>
            <View
              style={{
                width: "50%",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                }}
              >
                Shipping/Handling
              </Text>
            </View>
            <View
              style={{
                width: "50%",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  textAlign: "right",
                }}
              >
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(freight) + " USD"}
              </Text>
            </View>
          </View>
          {taxLine}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              padding: 5,
            }}
          >
            <View
              style={{
                width: "50%",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Helvetica-Bold",
                  fontWeight: "bold",
                }}
              >
                Grand Total
              </Text>
            </View>
            <View
              style={{
                width: "50%",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  textAlign: "right",
                  fontFamily: "Helvetica-Bold",
                  fontWeight: "bold",
                }}
              >
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(grandSubtotal + tax + freight)}
              </Text>
            </View>
          </View>
        </View>
      </View>
    )
    let quoteNumber = ""
    if (this.props.project.quoteId) {
      quoteNumber = "QQ" + String(this.props.project.quoteId).padStart(5, "0")
    }
    designGrid.unshift(
      <View style={styles.quickQuoteHeading} wrap={false}>
        <View style={styles.quickQuoteHeadingLeft}>
          <Text style={styles.projectDesignText}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>Quotation No: </Text>{" "}
            # {quoteNumber}
          </Text>
          <Text style={styles.projectDesignText}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>Project: </Text>{" "}
            form+ {this.props.project.name}
          </Text>
          <Text style={styles.projectDesignText}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>
              Territory Manager:{" "}
            </Text>{" "}
            {this.props.fsRep.name}
          </Text>
          <Text style={styles.projectDesignText}></Text>
          <Text style={styles.projectDesignText}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>Quote Date: </Text>{" "}
            {printDate}
          </Text>
          <Text style={styles.projectDesignText}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>
              Quotation Valid Thru:{" "}
            </Text>{" "}
            {validDate}
          </Text>
          <Text style={styles.projectDesignText}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>Terms: </Text> 100%
            pre-paid
          </Text>
        </View>
        <View style={styles.quickQuoteHeadingRight}>
          <Text style={styles.projectDesignText}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>To: </Text>{" "}
          </Text>
          <Text style={styles.projectDesignText}>All Bidders</Text>
        </View>
      </View>
    )
    designGrid.unshift(
      <View style={styles.quickQuoteTitle} wrap={false}>
        <Text style={styles.quickQuoteTitleLeft}>QUOTATION</Text>
        <Text style={styles.quickQuoteTitleRight}># {quoteNumber}</Text>
      </View>
    )
    pages.push(
      <Page
        size="LETTER"
        wrap={true}
        style={styles.bodyStyle}
        key={"design-configuration-overview-1"}
      >
        {header}
        <View style={styles.quickQuoteItems} wrap={true}>
          {designGrid}
        </View>
        {footer}
      </Page>
    )

    let titleText = {
      textTransform: "uppercase",
      fontFamily: "Helvetica-Bold",
      fontSize: 10,
      marginBottom: 10,
    }

    let paragraphText = {
      fontSize: 10,
      marginBottom: 10,
      lineHeight: 1.5,
      textAlign: "left",
    }

    let lineText = {
      fontSize: 10,
      marginBottom: 0,
      lineHeight: 1.5,
      textAlign: "left",
    }

    let notesAndPaymentTerms = hardwareKits.map((kit, i) => {
      return (
        <View
          wrap={false}
          style={{
            marginBottom: 10,
          }}
        >
          <Text style={titleText}>{kit.title}</Text>
          <Text
            style={{
              fontSize: 10,
              marginBottom: 10,
            }}
          >
            {kit.subTitle}
          </Text>
          <Text style={paragraphText}>{kit.contents}</Text>
        </View>
      )
    })

    notesAndPaymentTerms.push(
      <View
        wrap={false}
        style={{
          marginBottom: 10,
        }}
      >
        <Text style={titleText}>General Notes</Text>
        <Text style={titleText}>Desired Ship Date</Text>
        <Text style={paragraphText}>
          For scheduling purposes, it is critical to establish an accurate
          Desired Ship Date at time of order placement. If the necessary deposit
          and approvals are not provided in time to meet the customer-confirmed
          Desired Ship Date (must be within one-year from date of order
          placement), then pricing is subject to change at the discretion of
          F+S. If a Desired Ship Date is not provided by the purchasing party at
          time of order placement, pricing for orders not approved and released
          to manufacturing within four months of order placement is subject to
          change at the discretion of F+S. Your F+S order administrator will
          provide a general timeline of a proval milestones necessary to release
          your order to manufacturing, and for the manufacturing lead time to
          begin.
        </Text>
      </View>
    )

    notesAndPaymentTerms.push(
      <View
        wrap={false}
        style={{
          marginBottom: 10,
        }}
      >
        <Text style={titleText}>Leadtime</Text>
        <Text style={paragraphText}>
          Shipment from our facility will be apx 10 weeks from receipt of 50%
          deposit, signed F+S Order Acknowledgment and signed F+S Approval
          Drawings, as applicable. All ship date references are estimated and
          are not guaranteed. F+S lead times apply to standard F+S products and
          offerings. Orders involving non-F+S, third-party finish materials may
          be subject to longer lead times. F+S does not assume liability for
          delays in order completion caused by third-party finish material
          suppliers.
        </Text>
      </View>
    )

    notesAndPaymentTerms.push(
      <View
        wrap={false}
        style={{
          marginBottom: 10,
        }}
      >
        <Text style={titleText}>Pricing And Conformity</Text>
        <Text style={paragraphText}>
          Values are based on the specific scope of supply and timing quoted.
          Should scope or timing requirements change, values may be subject to
          change. Pricing reflects our understanding of the requirements based
          on the information provided to us from the quoted or ordering party.
          The quoted or ordering party is responsible for confirming quantities,
          sizes, finishes and conformity with any relevant plans and
          specifications. These considerations are independent of any prior F+S
          specification efforts, drawings or engineering details that may have
          previously been issued by F+S or otherwise obtained by the quoted or
          ordering party or any involved third-party. Payment terms are per most
          recent Quotation or F+S Order Acknowledgment and are not contingent
          upon any Condition Precedent stipulations by the buyer. F+S rejects
          any Condition Precedent or Retainage clauses from buying parties. Any
          fees associated with customer-stipulated construction management
          software or billing services will be charged back to customer on
          invoice. Pricing assumes fabrication in accordance with F+S standard
          or recommended manufacturing methods.
        </Text>
      </View>
    )

    notesAndPaymentTerms.push(
      <View
        wrap={false}
        style={{
          marginBottom: 10,
        }}
      >
        <Text style={titleText}>Delivery</Text>
        <Text style={paragraphText}>
          Receiving parties are responsible for off-loading all materials from
          the carrier which may require a forklift, pallet jack or other
          specialized equipment, tools and appropriate manpower. Receiving
          parties are responsible to document via digital images, notate on
          delivery receipt prior to signing, and immediately notify an F+S
          representative of any visible damage to the exterior of crating or
          packaging. Actual inspection of goods must be completed and any damage
          claims filed within five business days of delivery. Unpacking,
          assembly or installation are the responsibility of the buying party or
          their assigns. All deliveries are curbside if no loading dock is
          available or accessible at delivery site.
        </Text>
      </View>
    )

    notesAndPaymentTerms.push(
      <View
        wrap={false}
        style={{
          marginBottom: 10,
        }}
      >
        <Text style={paragraphText}>
          For questions regarding lead times, deposits, approvals, etc. please
          contact...
        </Text>
        <Text style={titleText}>Territory Manager Contact</Text>
        <Text style={lineText}>{this.props.fsRep.name}</Text>
        <Text style={lineText}>
          {formatPhoneNumber(this.props.fsRep.phone)}
        </Text>
        <Text style={lineText}>{this.props.fsRep.email}</Text>
      </View>
    )

    notesAndPaymentTerms.push(
      <View
        wrap={false}
        style={{
          marginBottom: 10,
        }}
      >
        <Text
          style={{
            fontSize: 10,
            marginBottom: 10,
            marginTop: 50,
            width: "50%",
            borderTop: 1,
          }}
        >
          Customer Signature of Acceptance
        </Text>
        <Text style={paragraphText}>
          BY RELEASING F+S TO MANUFACTURE PRODUCT AND / OR TAKING DELIVERY OF
          SAME, BUYER IS ALSO ACKNOWLEDGING HAVING READ AND AGREEING TO THE
          EXPANDED FORMS+SURFACES, INC TERMS & CONDITIONS OF SALE THAT CAN BE
          VIEWED AT: WWW.FORMS-SURFACES.COM/TERMS
        </Text>
      </View>
    )

    notesAndPaymentTerms.push(
      <View
        wrap={false}
        style={{
          marginBottom: 10,
        }}
      >
        <Text style={titleText}>Payment Instructions</Text>
        <Text style={lineText}>
          USA (For domestic USA entities only, ACH or Wire)
        </Text>
        <Text style={lineText}>Beneficiary’s Name: Forms + Surfaces, Inc</Text>
        <Text style={lineText}>Bank Name: Dollar Bank</Text>
        <Text style={lineText}>
          Bank Address: 3 Gateway Center, Eleven South
        </Text>
        <Text style={lineText}>Pittsburgh, PA 15222</Text>
        <Text style={lineText}>ABA Number: 243074385</Text>
        <Text style={lineText}>Account Number: 0908055515</Text>
      </View>
    )

    pages.push(
      <Page
        size="LETTER"
        wrap={true}
        style={styles.bodyStyle}
        key={"design-configuration-overview-9990"}
      >
        {header}
        <View style={styles.quickQuoteItems} wrap={true}>
          {notesAndPaymentTerms}
        </View>
        {footer}
      </Page>
    )

    this.props.project.designs.forEach((design, i) => {
      let designNotes = ""
      let descriptionBlocks = []
      let productTitle = null
      let productModel = null
      let weight = null
      let quantity = null
      if (design?.smartDescription) {
        let metaDescription = cloneDeep(design.smartDescription)
        let hardware = design.pricing.lineItems.filter(item => {
          return item.hardwareKit
        })
        hardware.forEach((item, i) => {
          let findDesc = metaDescription.find(desc => {
            return desc.label === item.description
          })
          if (!findDesc) {
            metaDescription.push({
              label: item.description,
              description: item.shortDescription + " - Qty " + item.quantity,
            })
          }
        })
        let model = ""
        if (design.pricing?.lineItems?.length) {
          model = design.pricing.lineItems[0].model
        }
        productTitle = (
          <Text wrap={true} style={styles.productTitle}>
            {metaDescription[0].description}
          </Text>
        )

        productModel = (
          <Text wrap={true} style={styles.productTitle}>
            {model}
          </Text>
        )

        for (i = 1; i < metaDescription.length; i++) {
          let description = metaDescription[i].description
            .split(/\\n/)
            .join("\n")
          let label = metaDescription[i].label
          descriptionBlocks.push({
            label: label,
            description: description,
          })
        }

        weight = (
          <Text wrap={true} style={styles.bodyTextStyle}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>Weight: </Text>
            {design.pricing.weightTotalPrint === "0.00 lbs."
              ? "Weight Not Available"
              : design.pricing.weightTotalPrint}
          </Text>
        )

        quantity = (
          <Text wrap={true} style={styles.bodyTextStyle}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>Quantity: </Text>
            {design.quantity}
          </Text>
        )

        if (design.notes) {
          designNotes = (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Text style={styles.bodyTextStyle}>{design.notes}</Text>
            </View>
          )
        }
      }
      smartCode = design.smartCode
      let image = null
      let imageBorder = null
      if (design.imagePath) {
        let imageSrc = process.env.GATSBY_IMAGE_URL + design.imagePath
        imageBorder = (
          <Canvas paint={this.drawSquare} style={styles.imageBorder} />
        )
        image = (
          <Image
            src={{
              uri: imageSrc,
              method: "GET",
              headers: { "Cache-Control": "no-cache" },
              body: "",
            }}
            style={styles.productImage}
          />
        )
      }
      pages.push(
        <Page
          size="LETTER"
          wrap={true}
          style={styles.bodyStyle}
          key={"design-configuration-overview-2" + i}
        >
          {header}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "space-between",
              gap: "10",
              marginBottom: "20",
            }}
          >
            <View wrap={true} fixed style={styles.productDescriptionStyle}>
              {productTitle}
              {productModel}
              {quantity}
              {weight}
              <Text wrap={true} style={styles.bodyTextStyle}>
                {descriptionBlocks.map((item, i) => {
                  return (
                    <Text key={i}>
                      <Text style={{ fontFamily: "Helvetica-Bold" }}>
                        {item.label}:{" "}
                      </Text>
                      {item.description}
                      {"\n"}
                    </Text>
                  )
                })}
              </Text>
            </View>
            <View
              style={{
                height: "100%",
              }}
            >
              {imageBorder}
              {image}
            </View>
          </View>
          {designNotes}
          {footer}
          <Text wrap={true} style={styles.projectSmartCode}>
            Smart Code: {smartCode}
          </Text>
        </Page>
      )
    })

    let renderPages = pages.map(function (object) {
      return object
    })

    let outputDocument = <Document>{renderPages}</Document>
    if (!isBrowser) {
      outputDocument = null
    }

    return outputDocument
  }
}

export default ProjectPDF
