import React, { Component } from "react"
import Layout from "../layout-mobile"
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Snackbar,
  Alert,
} from "@mui/material"
import { getProductDownloadsList } from "../../utils/product-info"
import CloseIcon from "@mui/icons-material/Close"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import PreviewItem from "./preview"
import ProjectPDF from "../ProjectPDF"
import QuotePDF from "../ProjectPDFQuote"
import { pdf } from "@react-pdf/renderer"
import PopInPanel from "../PopInPanel"
import AccountInfoComplete from "../AccountInfoComplete"
import EditProjectDetails from "../ProductViewer/project-form-update"
import DesignItem from "./design-item"
import AddProductBrowser from "./add-product-browser"
import LoadingBox from "../loading-box"
import saveAs from "file-saver"
import styled from "styled-components"
import {
  getProject,
  updateProject,
  deleteProject,
  duplicateProject,
} from "../../utils/project"
import { createDesign } from "../../utils/design"
import { getUserInfo } from "../../utils/user"
import { getPricingForProject } from "../../utils/pricing"
import { isBrowser, navigate } from "../../utils/misc"

class UserDashboard extends Component {
  constructor(props) {
    super(props)
    this.btnRef = React.createRef()
    this.state = {
      myProject: null,
      errMsg: "",
      successMsg: "",
      panel1: true,
      panel2: true,
      loading: true,
      pricing: null,
      userInfo: null,
      showMenu: false,
      showEmpty: true,
      projectLoaded: false,
      filterKeyword: "",
      filterCategories: [],
      filterMostRecent: false,
      selectedProduct: null,
      sortType: "chronoDesc",
      showDownloads: false,
      showPricingPreview: false,
      showPricingSummary: false,
      showPricingSubmit: false,
      addProductBrowserVisible: false,
      showUserInfoComplete: false,
      showUserInfoComplete2: false,
      deleteDialogOpen: false,
      showSubmitConfirm: false,
      showEditProject: false,
      includeTax: false,
      includeFreightInsurance: false,
      downloads: [],
      taxRate: { rate: 0, handlingTaxable: true, freightTaxable: true },
      fsRep: {
        name: "F+S Marketing",
        title: "",
        phone: "800.451.0410",
        email: "marketing@forms-surfaces.com",
        location: "Pittsburgh / Carpenteria / Phoenix",
      },
    }
  }

  componentDidMount() {
    this.getProject()
    this.handleGetUserInfo()
  }

  setRepTaxRate = data => {
    this.setState({
      taxRate: data.taxRate,
      fsRep: data.fsRep,
      includeTax: data.includeTax,
      includeFreightInsurance: data.includeFreightInsurance
    })
  }

  onClose = () => {
    this.setState({
      showDownloads: false,
      showPricingPreview: false,
      showPricingSummary: false,
      showPricingSubmit: false,
      addProductBrowserVisible: false,
      showUserInfoComplete: false,
      showUserInfoComplete2: false,
      deleteDialogOpen: false,
      showSubmitConfirm: false,
      showEditProject: false,
    })
  }

  setError = err => {
    if (err) {
      this.setState({
        errMsg: err.toString(),
        loading: false,
      })
    }
  }

  changeSortType = sortType => {
    let newSort = sortType
    if (newSort === this.state.sortType) {
      if (newSort.includes("Desc")) {
        newSort = newSort.replace("Desc", "")
      } else {
        newSort = newSort + "Desc"
      }
    }
    this.setState({
      sortType: newSort,
      sortEl: null,
    })
  }

  handleSelectProduct = product => {
    this.setState({
      selectedProduct: product,
    })
  }

  handleAddProduct = () => {
    if (this.state.selectedProduct) {
      this.handleSetLoading(true)
      let product = this.state.selectedProduct
      let smartCode = product.frontmatter.default_configuration
      let design = {
        smartCode: smartCode,
        smartDescription: [],
        name: product.frontmatter.product_name,
        productClass: product.frontmatter.product_class,
        dimensions: "",
        configuratorId: product.frontmatter.configurator_id,
        project: this.state.myProject._id,
        file: "",
      }
      createDesign(design)
        .then(designData => {
          this.handleSetLoading(false)
          navigate("/design/" + designData._id)
        })
        .catch(err => {
          this.handleSetLoading(false)
          this.setState({
            errMsg: err.message,
          })
        })
    }
  }
  handleGetUserInfo = callbackFunction => {
    this.setState({
      loading: true,
    })
    getUserInfo()
      .then(info => {
        this.setState(
          {
            userInfo: info,
            loading: false,
          },
          function () {
            if (callbackFunction) {
              callbackFunction()
            }
          }
        )
      })
      .catch(err => {
        this.setState({
          errMsg: err.toString(),
          loading: false,
        })
      })
  }

  handleDuplicate = () => {
    let project = { ...this.state.myProject }
    project.name = "Copy of " + project.name
    this.handleSetLoading(true)
    duplicateProject(project)
      .then(
        function (data) {
          if (isBrowser) {
            navigate("/project/" + data._id)
            this.getProject(data._id)
          }
        }.bind(this)
      )
      .catch(err => {
        console.log(err)
      })
  }

  getProject = projectId => {
    if (!projectId) {
      projectId = this.props.id
    }
    getProject(projectId)
      .then(
        function (data) {
          if (data?.designs?.length) {
            this.btnRef.current.focus()
          }
          getPricingForProject(
            data,
            this.props.products,
            this.props.hardwareKits
          ).then(
            function (pricing) {
              let updateObject = {
                myProject: data,
                loading: false,
                projectLoaded: true,
                project: pricing,
              }
              this.setState(updateObject)
            }.bind(this)
          )
        }.bind(this)
      )
      .catch(
        function (err) {
          this.setError(err)
        }.bind(this)
      )
  }

  handleSetLoading = loading => {
    this.setState({
      loading: loading,
    })
  }

  handleCloseError = () => {
    this.setState({
      errMsg: "",
    })
  }

  handleCloseSuccess = () => {
    this.setState({
      successMsg: "",
    })
  }

  downloadPDF = () => {
    if (!this.state.userInfo.complete) {
      this.setState({
        showUserInfoComplete2: true,
        loading: false,
      })
    } else {
      this.handleSetLoading(true)
      let fileName = this.state.myProject.name
        .toLowerCase()
        .replaceAll(" ", "-")
      if (isBrowser) {
        const projectPDF = <ProjectPDF project={this.state.myProject} />
        pdf(projectPDF)
          .toBlob()
          .then(
            function (blob) {
              this.handleSetLoading(false)
              var pdfURL = window.URL.createObjectURL(blob)
              let tempLink = document.createElement("a")
              tempLink.href = pdfURL
              tempLink.setAttribute("download", fileName + ".pdf")
              tempLink.click()
            }.bind(this)
          )
      }
    }
  }

  handleZipAllFiles = async documentType => {
    this.setState({
      filesToDownload: documentType,
    })
    if (this.state?.userInfo?.complete) {
      this.zipAllFiles(documentType)
    } else {
      this.setState({
        showUserInfoComplete3: true,
      })
    }
  }
 
  zipAllFiles = async documentType => {
    this.handleSetLoading(true)
    let allFiles = []
    let zipFileName =
      this.state.myProject.name.replace(/[^a-zA-Z0-9 ._-]/g, "").toLowerCase() +
      "-" +
      documentType +
      ".zip"
    if (!documentType) {
      zipFileName =
        this.state.myProject.name
          .replace(/[^a-zA-Z0-9 ._-]/g, "")
          .toLowerCase() + ".zip"
      const projectPDF = <ProjectPDF project={this.state.myProject} />
      pdf(projectPDF)
        .toBlob()
        .then(
          function (blob) {
            if (isBrowser) {
              var pdfURL = window.URL.createObjectURL(blob)
              allFiles.push({
                productName: this.state.myProject.name
                  .replace(/[^a-zA-Z0-9 ._-]/g, "")
                  .toLowerCase(),
                configuratorId: this.state.myProject._id,
                name: "Specification Sheet",
                url: pdfURL,
              })
            }
          }.bind(this)
        )
    }
    for (let category = 0; category < this.state.downloads.length; category++) {
      for (
        let download = 0;
        download < this.state.downloads[category].files.length;
        download++
      ) {
        if (
          documentType ===
            this.state.downloads[category].category.slug.current ||
          !documentType
        ) {
          allFiles.push({
            fileName:
              this.state.downloads[category].files[download].file
                .originalFilename,
            url: this.state.downloads[category].files[download].file.url,
          })
        }
      }
    }

    const zip = require("jszip")()
    for (let file = 0; file < allFiles.length; file++) {
      // Zip file with the file name.
      let fileUrl = allFiles[file].url
      let response = await this.downloadFile(fileUrl)
      let fileName = allFiles[file].fileName
      if (!fileName) {
        fileName = allFiles[file].productName
          .replace(/_|&|,|\/| /g, "-")
          .toLowerCase()
        fileName =
          allFiles[file].name + "-" + fileName + "-" + (file + 1) + ".pdf"
      }
      zip.file(fileName, response)
    }
    let outputFileName = zipFileName
    zip.generateAsync({ type: "blob" }).then(content => {
      this.handleSetLoading(false)
      saveAs(content, outputFileName)
    })
  }

  downloadFile = async filename => {
    if (isBrowser) {
      let response = await fetch(filename, {
        mode: "cors",
      })
      return await response.blob()
    } else {
      return null
    }
  }

  handleSubmitProject = () => {
    if (this.state.userInfo.complete) {
      this.setState({
        showSubmitConfirm: true,
      })
    } else {
      this.setState({
        showUserInfoComplete: true,
      })
    }
  }

  handleSubmitConfirmed = async () => {
    if (isBrowser) {
      this.handleSetLoading(true)
      const projectPDF = <ProjectPDF project={this.state.myProject} />
      let blob = await pdf(projectPDF).toBlob()
      let project = { ...this.state.myProject }
      var reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = function () {
        var base64data = reader.result
        project.file = base64data
        updateProject(project)
          .then(
            function (data) {
              this.handleSetLoading(false)
              navigate("/dashboard")
            }.bind(this)
          )
          .catch(
            function (err) {
              this.setError(err)
            }.bind(this)
          )
      }.bind(this)
    }
  }

  handleDesignQuantityUpdate = (id, quantity) => {
    let project = { ...this.state.myProject }
    let design = project.designs.find(design => {
      return design._id === id
    })
    if (design) {
      design.quantity = parseInt(quantity)
    }
    getPricingForProject(
      project,
      this.props.products,
      this.props.hardwareKits
    ).then(
      function (pricing) {
        this.setState({
          myProject: project,
        })
      }.bind(this)
    )
  }

  handleDialogClose = () => {
    this.setState({
      deleteDialogOpen: false,
    })
  }

  handleDelete = () => {
    this.handleSetLoading(true)
    deleteProject(this.state.myProject._id)
      .then(data => {
        navigate("/dashboard")
      })
      .catch(err => {
        this.setError(err)
      })
  }

  handleZipUpdate = zip => {
    if (isBrowser) {
      let project = { ...this.state.myProject }
      this.handleSetLoading(true)
      project.zip = zip
      updateProject(project)
        .then(
          function (data) {
            this.setState({
              myProject: data,
            })
            this.handleSetLoading(false)
          }.bind(this)
        )
        .catch(
          function (err) {
            this.setError(err)
          }.bind(this)
        )
    }
  }

  selectDesign = (designID, e) => {
    let myProject = { ...this.state.myProject }
    let designs = myProject.designs
    let designSelected = designs.find(object => {
      return object._id === designID
    })
    designSelected.selected = e.target.checked
    myProject.designs = designs
    this.setState({
      myProject: myProject,
    })
  }

  showPreview = () => {
    this.setState({ showMenu: false })
    this.setVisiblePage("preview")
  }

  handleProjectPricing = () => {
    if (
      this.state.userInfo?.email.toLowerCase().includes("@forms-surfaces.com")
    ) {
      if (this.state.userInfo.complete) {
        this.setState({
          showPricingPreview: true,
          showPricingSummary: false,
        })
      } else {
        this.setState({
          showUserInfoComplete: true,
        })
      }
    } else {
      if (this.state.userInfo?.complete) {
        this.setState({
          showPricingSubmit: true,
        })
      } else {
        this.setState({
          showUserInfoComplete: true,
        })
      }
    }
  }

  handleDownloadQuickQuote = async () => {
    if (isBrowser) {
      this.handleSetLoading(true)
      updateProject(this.state.myProject)
        .then(data => {
          let fileName = this.state.myProject.name
            .toLowerCase()
            .replaceAll(" ", "-")
          const projectPDF = (
            <QuotePDF
              project={this.state.myProject}
              taxRate={this.state.includeTax ? this.state.taxRate : null}
              includeFreightInsurance={this.state.includeFreightInsurance}
              fsRep={this.state.fsRep}
            />
          )

          pdf(projectPDF)
            .toBlob()
            .then(
              function (blob) {
                this.handleSetLoading(false)
                var pdfURL = window.URL.createObjectURL(blob)
                let tempLink = document.createElement("a")
                tempLink.href = pdfURL
                tempLink.setAttribute("download", fileName + "-quote.pdf")
                tempLink.click()
              }.bind(this)
            )
        })
        .catch(err => {
          console.log(err)
          this.setState({ errMsg: err.toString })
        })
    }
  }

  getDownloads = async () => {
    let urls = []
    for (
      let design = 0;
      design < this.state.myProject?.designs?.length;
      design++
    ) {
      let product = this.props.products.find(item => {
        return (
          item.node.childMarkdownRemark.frontmatter.configurator_id ===
          this.state.myProject.designs[design].configuratorId
        )
      })
      if (product?.node?.childMarkdownRemark?.frontmatter?.website_link) {
        urls.push(product?.node?.childMarkdownRemark?.frontmatter?.website_link)
      }
    }
    let downloads = await getProductDownloadsList(urls)
    this.setState({
      downloads: downloads,
    })
  }

  render() {
    let designs = null
    let grandSubtotal = 0
    let sortText = "Newest"
    let sortAlphaText = "Alphabetical Ascending"
    let projectName = this.state.myProject?.name || ""
    if (this.state.myProject?.locked) {
      projectName = (
        <>
          <svg
            width="16"
            height="20"
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 0C5.243 0 3 2.243 3 5V8H2C1.46957 8 0.960859 8.21071 0.585786 8.58579C0.210714 8.96086 0 9.46957 0 10V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H14C14.5304 20 15.0391 19.7893 15.4142 19.4142C15.7893 19.0391 16 18.5304 16 18V10C16 9.46957 15.7893 8.96086 15.4142 8.58579C15.0391 8.21071 14.5304 8 14 8H13V5C13 2.243 10.757 0 8 0ZM5 5C5 3.346 6.346 2 8 2C9.654 2 11 3.346 11 5V8H5V5Z"
              fill="black"
            />
          </svg>
          {projectName}
        </>
      )
    }
    let toolFunctions = (
      <>
        <Button
          variant="contained"
          disabled={this.state?.project?.designs?.length === 0}
          onClick={() => {
            this.getDownloads()
            this.setState({ showDownloads: true })
          }}
        >
          Downloads
        </Button>
        <Button
          variant="contained"
          disabled={this.state?.project?.designs?.length === 0}
          onClick={this.handleProjectPricing}
        >
          Project Pricing
        </Button>
        {!this.state.myProject?.locked && (
          <>
          <Button
            variant="contained"
            ref={this.btnRef}
            title="Add a Product"
            onClick={() => {
              this.setState({ addProductBrowserVisible: true })
            }}
          >
            Add Product
          </Button>

        </>
        )}
      </>
    )
    if (this.state?.myProject?.designs?.length > 0) {
      if (this.state.sortType === "alpha") {
        this.state.myProject.designs.sort((a, b) =>
          a.name.localeCompare(b.name)
        )
        sortText = "Alphabetical"
      }
      if (this.state.sortType === "alphaDesc") {
        this.state.myProject.designs.sort((a, b) =>
          b.name.localeCompare(a.name)
        )
        sortText = "Alphabetical"
        sortAlphaText = "Alphabetical Descending"
      }
      if (this.state.sortType === "chronoUpdatedDesc") {
        this.state.myProject.designs.sort((a, b) =>
          b.updated.localeCompare(a.updated)
        )
        sortText = "Newest"
      }
      if (this.state.sortType === "chronoUpdated") {
        this.state.myProject.designs.sort((a, b) =>
          a.updated.localeCompare(b.updated)
        )
        sortText = "Oldest"
      }
      if (this.state.sortType === "chronoDesc") {
        this.state.myProject.designs.sort((a, b) =>
          b.created.localeCompare(a.created)
        )
        sortText = "Newest"
      }
      if (this.state.sortType === "chrono") {
        this.state.myProject.designs.sort((a, b) =>
          a.created.localeCompare(b.created)
        )
        sortText = "Oldest"
      }
      designs = this.state?.myProject?.designs.map((design, i) => {
        grandSubtotal += parseInt(design.pricing.subTotal)
        if (this.state.filterKeyword) {
          let filterKeyword = this.state.filterKeyword.toLowerCase()
          if (!design.name.toLowerCase().includes(filterKeyword)) {
            return null
          }
        }
        return (
          <DesignItem
            design={design}
            key={i}
            project={this.state.myProject}
            getProject={this.getProject}
            products={this.props.products}
            hardwareKits={this.props.hardwareKits}
            handleSetLoading={this.handleSetLoading}
            filterCategories={this.state.filterCategories}
            filterMostRecent={this.state.filterMostRecent}
            showPopOver={false}
            selectDesign={this.selectDesign}
            deleteMode={this.state.deleteMode}
          />
        )
      })
      designs = (
        <>
          <SearchEditAddSection>
            <IconButton
              onClick={e => {
                this.setState({
                  sortEl: e.currentTarget,
                })
              }}
            >
              {sortText}
              {this.state.sortType.includes("Desc") ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={this.state.sortEl}
              open={this.state.sortEl ? true : false}
              onClose={() => {
                this.setState({ sortEl: null })
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                className={
                  ["chrono", "chronoDesc"].includes(this.state.sortType)
                    ? "active"
                    : ""
                }
                onClick={e => {
                  this.changeSortType("chronoDesc")
                }}
              >
                &nbsp;Chronological Created{" "}
                {this.state.sortType === "chrono" ? "Ascending" : "Descending"}
              </MenuItem>
              <MenuItem
                className={
                  ["chronoUpdated", "chronoUpdatedDesc"].includes(
                    this.state.sortType
                  )
                    ? "active"
                    : ""
                }
                onClick={e => {
                  this.changeSortType("chronoUpdatedDesc")
                }}
              >
                &nbsp;Chronological Edited{" "}
                {this.state.sortType === "chronoUpdated"
                  ? "Ascending"
                  : "Descending"}
              </MenuItem>
              <MenuItem
                className={
                  ["alpha", "alphaDesc"].includes(this.state.sortType)
                    ? "active"
                    : ""
                }
                onClick={e => {
                  this.changeSortType("alpha")
                }}
              >
                &nbsp;{sortAlphaText}
              </MenuItem>
            </Menu>
          </SearchEditAddSection>
          <section className="designs-list">{designs}</section>
        </>
      )
    } else {
      designs = (
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            border: "none",
            flexDirection: "column",
            maxWidth: "100%",
            height: "100%",
          }}
        >
          <p>
            <strong>No Products = No Fun</strong>
          </p>
          <p>
            Let’s spice it up by adding some products. Tap “Add a Product” up
            top.
          </p>
        </div>
      )
    }
    return (
      <Layout
        titleText={projectName}
        showMenu={true}
        menuOpen={this.state.showMenu}
        menuTitle="Project Menu"
        toolFunctions={toolFunctions}
      >
        {this.state.loading && <LoadingBox />}
        {designs}
        <PopInPanel
          open={this.state.showEditProject}
          title="Update Project"
          handleClose={e => {
            this.setState({
              showEditProject: false,
            })
            this.getProject()
          }}
        >
          <EditProjectDetails
            project={this.state.myProject}
            handleSetLoading={this.handleSetLoading}
            handleUpdate={project => {
              this.setState({
                myProject: project,
              })
            }}
            handleCancel={() => {
              this.setState({ showEditProject: false })
            }}
            onComplete={() => {
              this.handleSetLoading(false)
              this.setState({ showEditProject: false })
              this.getProject()
            }}
          />
        </PopInPanel>
        <PopInPanel
          open={this.state.showSubmitConfirm}
          title={"JUST A MOMENT..."}
          handleDrawerClose={e => {
            this.setState({
              showSubmitConfirm: false,
            })
          }}
          handleClose={e => {
            this.setState({
              showSubmitConfirm: false,
            })
          }}
        >
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <p>
              In order to provide accurate and timely pricing, we will need to
              lock this project once you submit your request. This means you
              will no longer be able to change or edit the products it contains.
            </p>
            <p>
              The good news: You can still download documents and duplicate this
              project after it's been locked. Any duplicates of this project
              will be available for editing.
            </p>
            <p>Would you like to submit this price request?</p>
          </div>
          <div></div>
          <Button
            style={{
              width: "100%",
              marginLeft: 0,
              textAlign: "center",
            }}
            variant="contained"
            onClick={this.handleSubmitConfirmed}
          >
            Submit
          </Button>
        </PopInPanel>
        <Snackbar
          open={this.state.errMsg ? true : false}
          autoHideDuration={6000}
          onClose={this.handleCloseError}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={this.handleCloseError}
          >
            {this.state.errMsg ? this.state.errMsg : ""}
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.state.successMsg ? true : false}
          autoHideDuration={6000}
          onClose={this.handleCloseSuccess}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity="success"
            onClose={this.handleCloseSuccess}
          >
            {this.state.successMsg ? this.state.successMsg : ""}
          </Alert>
        </Snackbar>
        {this.state.showUserInfoComplete && (
          <AccountInfoComplete
            userInfo={this.state.userInfo}
            handleSetLoading={this.handleSetLoading}
            handleSetError={err => {
              this.setState({ errMsg: err.toString })
            }}
            handleSetSuccess={success => {
              this.setState({ successMsg: success })
            }}
            onComplete={e => {
              this.setState({
                showPricingSubmit: true,
                loading: false,
                showUserInfoComplete: false,
              })
              this.handleGetUserInfo()
            }}
            handleCancel={e => {
              this.setState({
                showUserInfoComplete: false,
              })
            }}
          />
        )}
        {this.state.showUserInfoComplete2 && (
          <AccountInfoComplete
            userInfo={this.state.userInfo}
            handleSetLoading={this.handleSetLoading}
            handleSetError={err => {
              this.setState({ errMsg: err.toString })
            }}
            handleSetSuccess={success => {
              this.setState({ successMsg: success })
            }}
            onComplete={e => {
              this.setState({
                loading: false,
                showUserInfoComplete2: false,
              })
              this.handleGetUserInfo(
                function () {
                  this.downloadPDF()
                }.bind(this)
              )
            }}
            handleCancel={e => {
              this.setState({
                showUserInfoComplete2: false,
              })
            }}
          />
        )}
        {this.state.showUserInfoComplete3 && (
          <AccountInfoComplete
            userInfo={this.state.userInfo}
            handleSetLoading={this.handleSetLoading}
            handleSetError={err => {
              this.setState({ errMsg: err.toString })
            }}
            handleSetSuccess={success => {
              this.setState({ successMsg: success })
            }}
            onComplete={e => {
              this.setState({
                loading: false,
                showUserInfoComplete3: false,
              })
              this.handleGetUserInfo(
                function () {
                  this.zipAllFiles(this.state.filesToDownload)
                }.bind(this)
              )
            }}
            handleCancel={e => {
              this.setState({
                showUserInfoComplete3: false,
              })
            }}
          />
        )}
        <Dialog
          className="standard-dialog"
          open={this.state.deleteDialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <IconButton onClick={this.onClose}>
              <CloseIcon />
            </IconButton>
            <span>Delete this Project?</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This will delete all designs as well as this project. This action
              is permanent.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="underline-button"
              onClick={this.handleDialogClose}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={this.handleDelete}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          maxWidth={false}
          open={this.state.addProductBrowserVisible}
          onClose={e => {
            this.setState({ addProductBrowserVisible: false })
          }}
        >
          <DialogTitle>
            <IconButton onClick={this.onClose}>
              <CloseIcon />
            </IconButton>
            <span>Add a Product</span>
          </DialogTitle>
          <DialogContent sx={{ display: "flex", overflow: "hidden" }}>
            <AddProductBrowser
              products={this.props.products}
              handleSetLoading={this.handleSetLoading}
              onSelect={this.handleSelectProduct}
              onComplete={() => {
                this.setState({ addProductBrowserVisible: false })
                this.props.getProject()
              }}
            />
          </DialogContent>
          <DialogActions>
            {this.state.selectedProduct && (
              <Button
                variant="contained"
                onClick={this.handleAddProduct}
                className="no-overflow"
                style={{ maxWidth: "400px" }}
              >
                Add {this.state.selectedProduct.frontmatter.product_name}
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.showDownloads}
          fullWidth={true}
          maxWidth={"xs"}
          onClose={() => {
            this.setState({ showDownloads: false })
          }}
        >
          <DialogTitle>
            <IconButton onClick={this.onClose}>
              <CloseIcon />
            </IconButton>
            <span>Project Downloads</span>
          </DialogTitle>
          <DialogContent>
            <section className="downloads-list">
              <Button
                className="download-button"
                title="Specification Sheet"
                onClick={this.downloadPDF}
              >
                Specification Sheet
              </Button>
              {this.state.downloads.map(category => {
                return (
                  <Button
                    className="download-button"
                    key={category._key}
                    onClick={() => {
                      this.zipAllFiles(category.category.slug.current)
                    }}
                  >
                    {category.category.title}
                  </Button>
                )
              })}
            </section>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                this.zipAllFiles()
              }}
            >
              Download All
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.showPricingSubmit}
          fullWidth={true}
          maxWidth={"sm"}
          className={"status-caution"}
          onClose={() => {
            this.setState({ showPricingSubmit: false })
          }}
        >
          <DialogTitle sx={{ textAlign: "center" }}>
            <IconButton onClick={this.onClose}>
              <CloseIcon />
            </IconButton>
            <span>Project Pricing Request</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Just a moment...</DialogContentText>
            <DialogContentText>
              In order to provide accurate and timely pricing, we will need to
              lock this project once you submit your request.{" "}
              <strong>
                This means you will no longer be able to change or edit the
                products it contains.
              </strong>
            </DialogContentText>
            <DialogContentText>
              The good news: You can still download documents and duplicate this
              project after it’s been locked. Any duplicates of this project
              will be available for editing.Would you like to submit this price
              request?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="underline-button"
              onClick={e => {
                this.setState({ showPricingSubmit: false })
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={this.handleSubmitConfirmed}>
              Submit Pricing Request
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.showPricingPreview}
          fullWidth={true}
          maxWidth={"sm"}
          className="preview-dialog"
          onClose={() => {
            this.setState({ showPricingPreview: false })
          }}
        >
          <DialogTitle sx={{ textAlign: "center" }}>
            {!this.state.showPricingSummary && (
              <IconButton onClick={this.onClose}>
                <CloseIcon />
              </IconButton>
            )}
            {this.state.showPricingSummary && (
              <IconButton
                onClick={() => {
                  this.setState({ showPricingSummary: false })
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
            )}
            <span>Project Pricing Preview</span>
          </DialogTitle>
          <DialogContent>
            <PreviewItem
              project={this.state.myProject}
              handleSetLoading={this.handleSetLoading}
              handleDesignQuantityUpdate={this.handleDesignQuantityUpdate}
              handleSubmitProject={this.handleSubmitProject}
              handleZipUpdate={this.handleZipUpdate}
              setError={this.setError}
              taxZip={this.state?.myProject?.zip}
              setRepTaxRate={this.setRepTaxRate}
              handleShowPricingSummary={value => {
                this.setState({ showPricingSummary: value })
              }}
              showPricingSummary={this.state.showPricingSummary}
              handleSetError={err => {
                this.setState({ errMsg: err.toString })
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              disabled={grandSubtotal >= 10000}
              onClick={this.handleDownloadQuickQuote}
            >
              Download Quote
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                this.setState({
                  showPricingPreview: false,
                  showPricingSubmit: true,
                })
              }}
            >
              Submit Pricing Request
            </Button>
          </DialogActions>
        </Dialog>
      </Layout>
    )
  }
}

export default UserDashboard

const SearchEditAddSection = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 10px;
  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
    .MuiTextField-root {
      width: calc(100%);
      line-height: 3rem;
      div:before {
        border-bottom: none;
      }
      input {
        border-bottom: none;
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }
  }
  button {
    height: 3rem;
    line-height: 3rem;
    display: flex;
    gap: 5px;
    flex-direction: row;
    background-color: transparent;
  }
  .edit-button {
    width: 100px;
    background-color: #c8c8c8;
    color: #000;
  }
  .new-project-button {
    width: 40%;
  }
`
