import React, { Component } from "react"
import {
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material"
import ProductPDF from "../ProductPDF"
import { pdf } from "@react-pdf/renderer"
import { deleteDesign } from "../../utils/design"
import { mergeHardwareKitsIntoPricing } from "../../utils/pricing"
import DeleteIcon from "../../styles/img/delete.svg"
import { isBrowser, navigate } from "../../utils/misc"

class DesignItem extends Component {
  constructor(props) {
    super(props)
    this.designItem = React.createRef()
    this.state = {
      anchorEl: "",
      deleteDialogOpen: false,
      renameDialogOpen: false,
      designName: this.props.design.name,
      showOnBoard: this.props.onBoardVisible2,
    }
  }

  componentDidMount() {
    this.setState({
      showOnBoard: this.props.onBoardVisible2,
    })
  }

  componentDidUpdate() {}

  clickMenu = e => {
    this.setState({
      anchorEl: e.currentTarget,
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    })
  }

  handleDelete = () => {
    this.props.handleSetLoading(true)
    deleteDesign(this.props.design._id)
      .then(
        function (data) {
          this.setState(
            {
              deleteDialogOpen: false,
              anchorEl: null,
            },
            function () {
              this.props.getProject()
            }
          )
        }.bind(this)
      )
      .catch(function (err) {
        console.log(err)
      })
  }

  handleDialogClose = () => {
    this.setState({
      deleteDialogOpen: false,
      renameDialogOpen: false,
      anchorEl: null,
    })
  }

  handleNameUpdate = e => {
    this.setState({
      projectName: e.currentTarget.value,
    })
  }

  handleNavigate = () => {
    if (!this.props.project.locked) {
      navigate("/design/" + this.props.design._id)
    }
  }

  downloadPDF = () => {
    this.props.handleSetLoading(true)
    this.setState({
      anchorEl: null,
    })
    let product = this.props.products.find(object => {
      return (
        object.node.frontmatter.configurator_id ===
        this.props.design.configuratorId
      )
    })
    product.node = mergeHardwareKitsIntoPricing(
      product.node,
      this.props.hardwareKits
    )

    let fileName = this.props.design.name.toLowerCase().replaceAll(" ", "-")
    if (isBrowser) {
      let quantity = 1
      if (this.props.design.quantity) {
        quantity = this.props.design.quantity
      }
      let notes = ""
      if (this.props.design.notes) {
        notes = this.props.design.notes
      }
      let image = ""
      if (this.props.design.imagePath) {
        image =
          process.env.GATSBY_IMAGE_URL +
          this.props.design.imagePath +
          "?v=" +
          new Date(this.props.design.updated).getTime()
      }
      let metaData = {
        dimensions: this.props.design.dimensions,
        smartDescription: this.props.design.smartDescription,
        smartCode: this.props.design.smartCode.split("-"),
      }
      const productPDF = (
        <ProductPDF
          product={product.node}
          productMeta={metaData}
          pricingOptions={product.node.frontmatter.pricing_options}
          quantity={quantity}
          notes={notes}
          image={image}
        />
      )
      pdf(productPDF)
        .toBlob()
        .then(
          function (blob) {
            var pdfURL = window.URL.createObjectURL(blob)
            let tempLink = document.createElement("a")
            tempLink.href = pdfURL
            tempLink.setAttribute("download", fileName + ".pdf")
            tempLink.click()
            this.setState(
              {
                generatingPDF: false,
              },
              function () {
                this.props.handleSetLoading(false)
              }
            )
          }.bind(this)
        )
    }
  }

  handleToggleSelected = e => {
    this.props.selectDesign(this.props.design._id, e)
  }

  render() {
    let design = this.props.design
    let imageSrc =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/+9fPQAJewN8/jmw9AAAAABJRU5ErkJggg=="

    if (design.imagePath) {
      imageSrc =
        process.env.GATSBY_IMAGE_URL +
        design.imagePath +
        "?v=" +
        new Date(design.updated).getTime()
    }
    let timeDifference =
      new Date().getTime() - new Date(design.updated).getTime()
    let daysDifference = parseInt(timeDifference / (1000 * 3600 * 24))
    //Check filter values
    if (this.props.filterCategories.length) {
      let product = this.props.products.find(productItem => {
        return (
          productItem.node.frontmatter.configurator_id === design.configuratorId
        )
      })
      if (product) {
        if (
          !this.props.filterCategories.includes(
            product.node.frontmatter.product_category
          )
        ) {
          return null
        }
      }
    }

    if (this.props.filterMostRecent) {
      if (daysDifference >= 1) {
        return null
      }
    }

    return (
      <>
        <div style={{ position: "relative" }}>
          {!this.props.deleteMode && (
            <button
              className="row-button"
              onClick={this.handleNavigate}
              title={design.name}
              aria-label={design.name}
            />
          )}
          {this.props.deleteMode && (
            <div className="col-xs-2">
              <Checkbox
                checked={design.selected}
                onChange={this.handleToggleSelected}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          )}
          <img alt={design.name} src={imageSrc} />
          <h4>{design.name}</h4>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <h4>x{design.quantity}</h4>
            {!this.props.project.locked && (
              <IconButton
                onClick={() => {
                  this.setState({ deleteDialogOpen: true })
                }}
                title="Delete Design"
                disabled={this.props.project.locked}
                style={{
                  width: "48px",
                  height: "48px",
                }}
              >
                <img src={DeleteIcon} alt="Delete Design" />
              </IconButton>
            )}
          </div>
        </div>
        <Dialog
          open={this.state.deleteDialogOpen}
          className="status-alert"
          onClose={() => {
            this.setState({ deleteDialogOpen: false })
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete this Design?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Warning: this action is not recoverable. Proceed with caution.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ deleteDialogOpen: false })
              }}
            >
              Cancel
            </Button>
            <Button onClick={this.handleDelete} variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default DesignItem
